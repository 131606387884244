import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-66fe52f4"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "score-efficiency"
}
const _hoisted_2 = { class: "score-efficiency__heading" }
const _hoisted_3 = { class: "score-efficiency__label" }
const _hoisted_4 = { class: "score-efficiency__your-condition" }
const _hoisted_5 = { class: "score-efficiency__value" }
const _hoisted_6 = { class: "score-efficiency__value--augmented" }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.metabolicScoreValue)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('metabolicEvaluation.yourMetabolicScore')), 1),
        _createElementVNode("div", {
          class: _normalizeClass(["score-efficiency__result", _ctx.efficiencyColorClass])
        }, [
          _createElementVNode("div", null, [
            _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.$t('metabolicEvaluation.efficiency')), 1),
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t('metabolicEvaluation.yourCondition')) + ": " + _toDisplayString(_ctx.$t(`metabolicEvaluation.${_ctx.mark}`)), 1)
          ]),
          _createElementVNode("span", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.metabolicScoreValue), 1),
            _createTextVNode("/10 ")
          ])
        ], 2)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_7))
}
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-b01ca968"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_apexchart, {
        options: _ctx.chartOptions,
        series: _ctx.phasesSeries,
        height: 320
      }, null, 8, ["options", "series"])
    ]),
    _createVNode(_component_DataTable, { value: _ctx.phasesResume }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, { field: "TYPE" }),
        _createVNode(_component_Column, { field: "KRMR1" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.reclined')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('phasesCard.phasesDuration.6min')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "KRMR2" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.seated')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('phasesCard.phasesDuration.4min')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "CINTA 4 Km/h" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.4km')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('phasesCard.phasesDuration.3min')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "CINTA 5 Km/h" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.5km')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('phasesCard.phasesDuration.3min')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "CINTA 6 Km/h" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.6km')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('phasesCard.phasesDuration.3min')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "CINTA 7 Km/h" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.7km')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('phasesCard.phasesDuration.3min')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "CINTA 8 Km/h" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.8km')) + " ", 1),
            _createElementVNode("span", null, _toDisplayString(_ctx.$t('phasesCard.phasesDuration.3min')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_Column, { field: "Recovery" }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('phasesCard.phases.recup')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["value"])
  ], 64))
}